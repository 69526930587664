'use client';

import { useEffect, useState } from 'react';
import { GoogleTagManager } from '@next/third-parties/google';
import { GTM_TRACKING_ID } from '../../../constants';

export default function GoogleTagManagerInit() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsMounted(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return isMounted && (
    <GoogleTagManager gtmId={String(GTM_TRACKING_ID)} />
  );
}
